/* global Component */
class categoryMenuComponent extends Component {

    static name() {
        return "categoryMenuComponent";
    }

    static componentName() {
        return "categoryMenuComponent";
    }

    getProps() {
        return {
            category: {
                type: Object,
                required: true,
            },
        };
    }

    data() {
        return {
            selectedCategory: "",
            showMenu: "show",
            isMobile: $(window).width() <= 991,
        };
    }

    getComputed() {
        return {
            isFolder: function () {
                if (this.category.children) {
                    return this.category.children && this.category.children.length;
                }
            }
        };
    }

    getMethods() {
        return {
            toggle: this.toggle,
            filterByCategory:this.filterByCategory,
            closeSandwichMenu:this.closeSandwichMenu
        };
    }

    toggle() {
        if (this.isFolder) {
            this.open = !this.open;
        }
    }

    filterByCategory(storeCategory){
        if (this.isMobile) {
            this.emitEvent('closeSandwichMenu');
        }
        if(this.$store.state.cat_selected && storeCategory.code == this.$store.state.cat_selected.code)
            return;
        if(storeCategory) {
            window.scrollTo(0, 0);
            this.emitEvent("close-submenu");
            window.document.title = this.capitalize(storeCategory.name);
            let catRoutePath = (`${storeCategory.code}__${storeCategory.name}`);
            let globalCategory = this.$store.getters.getGlobalCategories;
            let paramCategory = this.$route.params.category ? this.$route.params.category.split("__")[0] : "";
            if(globalCategory.length > 0 && this.$route.params.category && (globalCategory.indexOf(storeCategory.code) == -1 && globalCategory.indexOf(paramCategory) != -1) && catRoutePath !== this.$route.params.category) {
                this.$router.push({
                    name: 'CategorySEO',
                    params: {category: this.$route.params.category},
                    query: {category: catRoutePath}
                });
            }
            else{
                if(globalCategory.length > 0 && globalCategory.indexOf(this.$store.state.cat_selected) !== -1) {
                    this.$router.push({
                        name: 'CategorySEO',
                        params: {category: this.$route.params.category},
                        query: {category: catRoutePath}
                    });
                }
                else {
                    this.$router.push({
                        name: 'CategorySEO',
                        params: {category: catRoutePath},
                        query: {category: catRoutePath}
                    });
                }
            }
        }
    }

    closeSandwichMenu() {
        if (this.isMobile) {
            $(this.$el).find('#navbarSupportedContent').collapse('hide');
        }
    }

    mounted() {
        return async function (){
            this.subscribeEvent('closeSandwichMenu', this.closeSandwichMenu);
        };
    }

    getTemplate() {
        return `<div class="item-link">
                    <li class="dropdown-item" v-if="!isFolder" @click.stop="filterByCategory(category)">
                        <span v-if="$store.state.setting.NoForceCategoryName" style="text-transform: none;"><i class="fas fa-angle-right"></i> {{category.name}}</span>
                        <span v-else><i class="fas fa-angle-right"></i> {{category.name}}</span>
                    </li>
                    <li v-else class="dropdown-item dropdown" @click.stop="filterByCategory(category)">
                         <span v-if="$store.state.setting.NoForceCategoryName" style="text-transform: none;" class="" :id="'navbarSubDropdown'+category.code" data-toggle="dropdown"  aria-haspopup="true" aria-labelledby="false">
                              <i class="fas fa-angle-right"></i>
                              {{category.name}}
                         </span>
                         <span v-else class="" :id="'navbarSubDropdown'+category.code" data-toggle="dropdown"  aria-haspopup="true" aria-labelledby="false">
                              <i class="fas fa-angle-right"></i>
                              {{capitalize(category.name)}}
                         </span>
                         <ul :id ="'menu'+category.code"  class="dropdown-menu category-preview show" :aria-labelledby="'navbarSubDropdown'+category.code">
                            <categoryMenuComponent v-for="(subcat,keycat) in category.children"
                                           :key="keycat"
                                           :category="subcat">
                            </categoryMenuComponent>
                         </ul>
                    </li>
              </div>`;
    }
}

categoryMenuComponent.registerComponent();
